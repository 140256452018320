import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import CommonHero from "../page_components/common/CommonHero";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Trading Terminal"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title="Privacy Policy"/>

                <div className="container text-justify">
                    <p className="pt-50">
                        This privacy policy (“Privacy Policy”) describes how CodeFX Sp. z o.o., registry code
                        0000883350,
                        address Zamknieta 10/1.5, 30-554 Krakow, Poland (“we” or “us”), as a data controller, collect,
                        use
                        and
                        disclose personal data, and the steps we take to protect such personal data, provided by you or
                        collected by us either on the website https://www.bitspreader.com/(“Website”) or when you use
                        the
                        services or products that we provide or may provide in the future (“Services”).
                    </p>
                    <p className="py-2">
                        By using Website, our Services or any other related product you signify your acceptance of this
                        Privacy Policy. Please read this Privacy Policy each time before using the Website or the
                        Services
                        and before submitting any information to us. If you do not agree with the terms of this Privacy
                        Policy, do not access or use the Services, the Website or any other aspect of our business.
                    </p>
                    <h2>1. Data we collect about you</h2>
                    <p className="py-2">
                        Personal data, or personal information, means any information about an individual from which
                        that
                        person can be identified. It does not include data where the identity has been removed
                        (anonymous
                        data). We may collect, use, store and transfer different kinds of personal data about you which
                        we
                        have grouped together follows:
                        <ul className="list-style1">

                            <li><b>identity data</b>, such as, first name, last name and date of birth;
                                contact data, such as, email address, mailing address and phone number;
                            </li>
                            <li><b>financial data</b>, such as, bank account, payment card details and billing address;
                                transaction data, such as, details about payments to and from you and other details of
                                the
                                Services
                                you have purchased from us;
                            </li>
                            <li><b>profile data</b>, such as, username, account number or password;</li>
                            <li><b>technical data</b>, such as, the Internet Protocol (IP) address used to connect your
                                computer to
                                the
                                Internet, your login information (if applicable), browser type and version, time zone
                                setting,
                                browser plug-in types and versions, operating system and platform, information about
                                your
                                visit,
                                including the full Uniform Resource Locators (URL), clickstream to, through and from our
                                Website
                                (including date and time), page response times, download errors, length of visits to
                                certain
                                pages,
                                page interaction information (such as scrolling, clicks, and mouseovers), methods used
                                to
                                browse
                                away from the page and any information of yours related to contact our customer service
                                team;
                            </li>
                            <li><b>usage data</b>, such as, information you viewed or searched for and information
                                about how
                                you
                                use our
                                Website and Services; and
                            </li>
                            <li><b>marketing and communications data</b>, such as, your preferences in receiving
                                marketing from
                                us
                                and our
                                third parties and your communication responses.
                            </li>
                            <li><b>
                                information from social media networks</b>. If you log in to our Services using your
                                social
                                media
                                account (for example, Facebook or Google) we will receive relevant information that
                                is
                                necessary to
                                enable our Services and authenticate you. The social media network will provide us
                                with
                                access to
                                certain information that you have provided to them, including your name, profile
                                image
                                and
                                e-mail
                                address. We use such information, together with any other information you directly
                                provide
                                to us
                                when registering or using our Services, to create your account and to communicate
                                with
                                you
                                about the
                                information, products and services that you request from us. You may also be able to
                                specifically
                                request that we have access to the contacts in your social media account so that you
                                can
                                send a
                                referral link to your family and friends. We will use, disclose and store all of
                                this
                                information in
                                accordance with this privacy policy.
                            </li>
                        </ul>
                    </p>
                    <p className="py-2">
                        We also collect, use and share aggregated data such as statistical or demographic data for any
                        purpose. Aggregated data may be derived from your personal data but is not considered personal
                        data
                        in law as this data does not directly or indirectly reveal your identity. For example, we may
                        aggregate your usage data to calculate the percentage of users accessing a specific website
                        feature.
                        However, if we combine or connect aggregated data with your personal data so that it can
                        directly or
                        indirectly identify you, we treat the combined data as personal data which will be used in
                        accordance with this privacy notice.
                    </p>
                    <p className="py-2">
                        We do not collect any special categories of personal data about you (this includes details about
                        your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                        political
                        opinions, trade union membership, information about your health and genetic or biometric data).
                    </p>
                    <h2>2. Methods of data collection</h2>
                    <p className="py-2">
                        We use different methods to collect data from and about you including through:
                    </p>
                    <ul className="list-style1">

                        <li><b>direct interactions</b>. You may give us your identity, contact and financial data by
                            filling in forms
                            or by corresponding with us by post, phone, email, skype and other messenger or otherwise.
                            This
                            includes personal data you provide when you apply for our Services, create an account on our
                            Website, subscribe to our Service or publications, request marketing to be sent to you,
                            enter a
                            competition, promotion or survey, or give us some feedback, including report a problem with
                            our
                            Website;
                        </li>

                        <li><b>automated technologies or interactions</b>. As you interact with our Website, we may
                            automatically
                            collect technical data about your equipment, browsing actions and patterns. We collect this
                            personal
                            data by using cookies, server logs and other similar technologies. We may also receive
                            technical
                            data about you if you visit other websites employing our cookies.
                        </li>
                        <li>
                            <b>third parties or publicly available sources</b>. We may receive personal data about you
                            from public
                            domain, third parties (such as, analytics providers, advertising networks, search
                            information
                            providers and providers of technical, payment and delivery services) or other websites we
                            operate or
                            the other services we provide.
                        </li>
                    </ul>
                    <p className="py-2">
                        Where we need to collect personal data by law, or under the terms of an agreement we have with
                        you
                        and you fail to provide that data when requested, we may not be able to perform the agreement we
                        have or are trying to enter into with you, including to provide you with the Services. In this
                        case,
                        we may have to cancel the Services you have with us but we will notify you if this is the case
                        at
                        the time.
                    </p>

                    <h2>3. Use of your personal data</h2>
                    <p className="py-2">
                        We will only use your personal data when the law allows us to. Most commonly, we will use your
                        personal data in the following circumstances:
                    </p>

                    <ul className="list-style1">
                        <li>where we need to perform the agreement, we are about to enter into or have entered into
                            with you;
                        </li>
                        <li>where it is necessary for our legitimate interests (or those of a third party) and your
                            interests
                            and fundamental rights do not override those interests;
                        </li>
                        <li>where we need to comply with a legal or regulatory obligation.</li>
                    </ul>
                    <p className="py-2">

                        Legitimate interest means the interest of our business in conducting and managing our business
                        to
                        enable us to provide you with the best Services and the best and most secure experience. We make
                        sure we consider and balance any potential impact on you (both positive and negative) and your
                        rights before we process your personal data for our legitimate interests. We do not use your
                        personal data for activities where our interests are overridden by the impact on you (unless we
                        have
                        your consent or are otherwise required or permitted to by law).
                    </p>
                    <p className="py-2">

                        Generally we do not rely on consent as a legal basis for processing your personal data other
                        than in
                        relation to sending third party direct marketing communications to you via email or text
                        message.
                        You have the right to withdraw consent to marketing at any time by contacting us.
                    </p>
                    <p className="py-2">

                        We have set out below, in a table format, a description of all the ways we plan to use your
                        personal
                        data, and which of the legal bases we rely on to do so. We have also identified what our
                        legitimate
                        interests are where appropriate. Note that we may process your personal data for more than one
                        lawful ground depending on the specific purpose for which we are using your data.
                    </p>

                    <table className="table text-left">
                        <tr>
                            <th>Purpose or Activity</th>
                            <th>Type of data</th>
                            <th>Lawful basis for processing including basis of legitimate interest</th>
                        </tr>
                        <tr>
                            <td>To register you as our client and decide whether and on what terms to provide the
                                Services
                            </td>
                            <td>Identity, Contact and Financial</td>
                            <td>Performance of an agreement with you</td>
                        </tr>
                        <tr>
                            <td>To process and deliver the Services including, to (a) provide customer support and
                                communicate with you, (b) manage payments and fees and verify transactions, (c) collect
                                and recover fees owed to us, and (d) resolve disputes and enforce agreements entered
                                between us
                            </td>
                            <td>Identity, Contact, Financial, Transaction, Marketing and Communications</td>
                            <td>Performance of an agreement with you;</td>
                        </tr>
                        <tr>
                            <td>Necessary for our legitimate interests</td>
                            <td>To prevent and investigate prohibited or illegal activities or fraud, including, prevent
                                money laundering and terrorist financing
                            </td>
                            <td>Identity, Contact, Financial, Transaction</td>
                        </tr>
                        <tr>
                            <td>Performance of an agreement with you;</td>
                            <td>Necessary for our legitimate interests</td>
                            <td>To manage our relationship with you, including: (a) notifying you about changes to our
                                Services, Website, general terms or this Privacy Policy and (b) asking you to leave a
                                review or take a survey
                            </td>
                        </tr>
                        <tr>
                            <td>Identity, Contact, Profile, Marketing and Communications</td>
                            <td>Performance of an agreement with you;</td>
                            <td>Necessary to comply with a legal obligation;</td>
                        </tr>
                        <tr>
                            <td>Necessary for our legitimate interests (to keep our records updated and to study how you
                                use our Services)
                            </td>
                            <td>To enable you to partake in a competition or complete a survey</td>
                            <td>Identity, Contact, Profile, Usage, Marketing and Communications</td>
                        </tr>
                        <tr>
                            <td>Performance of an agreement with you;</td>
                            <td>Necessary for our legitimate interests (to study how you use our Services, to develop
                                them and grow our business)
                            </td>
                            <td>To administer, customize, update, maintain, protect and improve the Website and the
                                Services (including troubleshooting, data analysis, testing, system maintenance,
                                support, reporting and hosting of data)
                            </td>
                        </tr>
                        <tr>
                            <td>Identity, Contact, Technical and Usage</td>
                            <td>Necessary for our legitimate interests (for running our business, provision of
                                administration and IT services, network security, to prevent fraud and in the context of
                                a business reorganisation);
                            </td>
                            <td>Necessary to comply with a legal obligation</td>
                        </tr>
                        <tr>
                            <td>To deliver relevant Website content and advertisements to you and measure or understand
                                the effectiveness of the advertising we serve to you
                            </td>
                            <td>Identity, Contact, Profile, Technical, Usage, Marketing and Communications</td>
                            <td>Necessary for our legitimate interests (to study how you use our Services, to develop
                                them, to grow our business and to inform our marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To use data analytics to improve our Website, Services, marketing, customer
                                relationships and experiences
                            </td>
                            <td>Technical and Usage</td>
                            <td>Necessary for our legitimate interests (to define types of clients for our Services, to
                                keep our Website updated and relevant, to develop our business and to inform our
                                marketing strategy)
                            </td>
                        </tr>
                        <tr>
                            <td>To make suggestions and recommendations to you about Services that may be of interest to
                                you
                            </td>
                            <td>Identity, Contact, Technical and Usage, Profile</td>
                            <td>Necessary for our legitimate interests (to develop our Services and grow our business)
                            </td>
                        </tr>
                    </table>
                    <p className="py-2">
                        We may use your identity, contact, profile, technical and usage data to form a view on what we
                        think you may want or need, or what may be of interest to you. This is how we decide which
                        Services and offers may be relevant for you (we call this marketing). You may receive marketing
                        communications from us if you have requested information from us or purchased Services from us
                        and, in each case, you have not opted out of receiving that marketing.
                    </p>
                    <p className="py-2">
                        You can ask us to stop sending you marketing messages at any time by following the opt-out links
                        on any marketing message sent to you or by contact us at any time.
                    </p>
                    <p className="py-2">
                        We will only use your personal data for the purposes for which we collected it, unless we
                        reasonably consider that we need to use it for another reason and that reason is compatible with
                        the original purpose. If you wish to get an explanation as to how the processing for the new
                        purpose is compatible with the original purpose, please contact us.
                    </p>


                    <h2>4. Cookies and similar technologies</h2>
                    <p className="py-2">Like most Internet sites, our Website may use
                        cookies and similar technologies to enhance your user experience and provide customization
                        and useful features to our Website and Services. You can set your browser to refuse all or
                        some browser cookies, or to alert you when websites set or access cookies. If you disable or
                        refuse cookies, please note that some parts of this Website may become inaccessible or not
                        function properly. For more information about the cookies we use, please see our <a className="read-more-link"
                            href="/cookies">Cookie Policy</a>.</p>

                    <h2>5. Disclosure of your personal
                        data</h2>

                    <p className="py-2">Except as outlined in this Privacy Policy, your personal data will never be sold
                        or disclosed with other companies or organizations for commercial purposes or otherwise.</p>
                    <p className="py-2">We reserve the right at all times to disclose any personal data that we have
                        collected when:
                        (a) permitted or required by law; or, (b) trying to protect against, prevent, investigate
                        actual or potential fraud, security issues, technical issues, unauthorized transactions or
                        other violations; or (c) trying to enforce the applicable terms of service or other
                        agreements; or (d) protecting against violations to the rights, property or safety of us,
                        our users or the public as required or permitted by applicable laws; or (e) you have given
                        your consent to do so. Finally, we may transfer personal data to a successor entity in
                        connection with a corporate merger, consolidation, partial or total sale of assets,
                        bankruptcy, or other corporate change.</p><p className="py-2">We may disclose your personal data
                    to our
                    contractors (who may be located outside of the European Economic Area (“EEA”) to process it
                    for us, for the purposes of processing transactions, collecting fees, identifying you, but
                    also for improving your user experience, performing business support functions or other
                    related tasks, based on our instructions and in compliance with the Privacy Policy and any
                    other applicable confidentiality and security requirements. In addition, transfers to our
                    contractors are covered by the service agreements with such contractors.</p><p
                    className="py-2">Whenever we
                    transfer your personal data out of the EEA, we ensure a similar degree of protection is
                    afforded to it by ensuring at least one of the following safeguards is implemented:</p>
                    <ul className="list-style1">
                        <li>we will only transfer your personal data to countries that have been deemed to provide
                            an adequate level of protection for personal data by the European Commission. For
                            further details, see <a className="read-more-link" target="_blank"
                                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
                                                    rel="noreferrer">European Commission: Adequacy of the protection
                                of personal data in non-EU countries</a>;
                        </li>
                        <li>where we use certain service providers, we may use specific contracts approved by the
                            European Commission which give personal data the same protection it has in Europe. For
                            further details, see <a className="read-more-link" target="_blank"
                                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
                                                    rel="noreferrer">European Commission: Model contracts for the
                                transfer of personal data to third countries</a>;
                        </li>
                        <li>where we use providers based in the US, we may transfer data to them if they are part of
                            the Privacy Shield which requires them to provide similar protection to personal data
                            shared between the Europe and the US. For further details, see <a className="read-more-link" target="_blank"
                                                                                              href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"
                                                                                              rel="noreferrer">European
                                Commission: EU-US Privacy Shield</a>.
                        </li>
                    </ul>

                    <h2>6. Data security</h2>
                    <p className="py-2">We adhere to the generally accepted industry standards to protect
                        your personal data from being accidentally lost, used or accessed in an unauthorised way,
                        altered or disclosed. Only our authorized employees, agents and contractors (who have agreed
                        to keep personal data secure and confidential) have access to the personal data on a need to
                        know basis.</p><p className="py-2">We store and process data, including personal data, in the EEA and
                    possibly in other countries through third parties that we use to operate and manage the
                    Services. When you access or use our Websites or the Services, or otherwise provide personal
                    data to us, you are consenting, on behalf of you and your authorized agents, (and
                    representing that you have the authority to provide such consent) to the processing and
                    transfer of personal data in and to the other countries which may have different privacy
                    laws from your or their country of residence. We will take all steps reasonably necessary to
                    ensure that your personal data is treated securely and in accordance with this Privacy
                    Policy.</p><p className="py-2">Any payment transactions will be encrypted. Where we have given you (or where
                    you have chosen) a password, which enables you to access certain parts of our Website, you
                    are responsible for keeping this password confidential. We ask you not to share a password
                    with anyone.</p><p className="py-2">You agree and acknowledge that the Internet may be subject to breaches of
                    security and that the submission of data over the Internet may not be secure. We strive to
                    use acceptable means to protect any personal data you share with us, however we cannot
                    guarantee its absolute security.</p><p className="py-2">Depending on the nature of the risks presented by the
                    proposed processing of your personal data, we will have in place the following appropriate
                    security measures:</p>
                    <ul className="list-style1">
                        <li>organisational measures (including but not limited to staff training and policy
                            development);
                        </li>
                        <li>technical measures (including but not limited to physical protection of data,
                            pseudonymization and encryption);
                        </li>
                        <li>securing ongoing availability, integrity, and accessibility (including but not limited
                            to ensuring appropriate back-ups of personal data are held).
                        </li>
                    </ul>
                    <p className="py-2">We have put in place procedures to deal with any suspected personal data breach and will
                        notify you and any relevant regulator of a breach where we are legally required to do
                        so.</p><p className="py-2">If you want to know more about our <a href="/security"
                                                                        className="read-more-link">security</a> practice,
                    please visit this link.</p>

                    <h2>7. Data retention</h2>
                    <p className="py-2">We will retain your personal data
                        only for so long as it is required for the purposes for which it was collected. This period
                        may extend beyond the end of your relationship with us, but only for so long as is
                        reasonably necessary for us to pursue legitimate business interests, conduct audits, comply
                        with (and demonstrate compliance with) legal obligations (e.g. taxation purposes), resolve
                        disputes and enforce our agreements.</p><p className="py-2">To determine the appropriate retention period for
                    personal data, we consider the amount, nature, and sensitivity of the personal data, the
                    potential risk of harm from unauthorised use or disclosure of your personal data, the
                    purposes for which we process your personal data and whether we can achieve those purposes
                    through other means, and the applicable legal requirements.</p><p className="py-2">When your personal data is
                    no longer required, we will destroy, delete or convert it into an anonymous form.</p>

                    <h2>8.
                        Third-party links</h2><p className="py-2">The privacy practices set forth in this Privacy Policy apply to the
                    Website and Services only. If you link to other web sites (including sites that offer or use
                    the Services or are promoted by us) or use the services of other service providers, please
                    review the privacy policies posted at those web sites or by the relevant service
                    providers.</p>

                    <h2>9. Age limitations</h2>

                    <p className="py-2">We do not allow use of our Services and the
                        Website by anyone younger than 18 years old. If you learn that anyone younger than 18 has
                        unlawfully provided us with personal data, please contact us and we will take steps to
                        delete such information.</p>


                    <h2>10. Your rights</h2><p className="py-2">Under certain circumstances, you have
                    following rights under data protection laws in relation to your personal data:</p>
                    <ul className="list-style1">
                        <li><strong>right to request access</strong> to your personal data. This enables you to
                            receive a copy of the personal data we hold about you and to check that we are lawfully
                            processing it;
                        </li>
                        <li><strong>right to request correction</strong> of the personal data that we hold about
                            you. This enables you to have any incomplete or inaccurate data we hold about you
                            corrected, though we may need to verify the accuracy of the new data you provide to us;
                        </li>
                        <li><strong>right to request erasure</strong> of your personal data. This enables you to ask
                            us to delete or remove personal data where there is no good reason for us continuing to
                            process it. You also have the right to ask us to delete or remove your personal data
                            where you have successfully exercised your right to object to processing (see below),
                            where we may have processed your information unlawfully or where we are required to
                            erase your personal data to comply with local law. Note, however, that we may not always
                            be able to comply with your request of erasure for specific legal reasons which will be
                            notified to you, if applicable, at the time of your request;
                        </li>
                        <li><strong>right to object to processing</strong> of your personal data where we are
                            relying on a legitimate interest (or those of a third party) and there is something
                            about your particular situation which makes you want to object to processing on this
                            ground as you feel it impacts on your fundamental rights and freedoms. You also have the
                            right to object where we are processing your personal data for direct marketing
                            purposes. In some cases, we may demonstrate that we have compelling legitimate grounds
                            to process your information which override your rights and freedoms;
                        </li>
                        <li><strong>right to request restriction of processing</strong> of your personal data. This
                            enables you to ask us to suspend the processing of your personal data in the following
                            scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the
                            data is unlawful but you do not want us to erase it; (c) where you need us to hold the
                            data even if we no longer require it as you need it to establish, exercise or defend
                            legal claims; or (d) you have objected to our use of your data but we need to verify
                            whether we have overriding legitimate grounds to use it;
                        </li>
                        <li><strong>right to request the transfer</strong> of your personal data to you or to a
                            third party. We will provide to you, or a third party you have chosen, your personal
                            data in a structured, commonly used, machine-readable format. Note that this right only
                            applies to automated information which you initially provided consent for us to use or
                            where we used the information to perform an agreement with you; and
                        </li>
                        <li><strong>right to withdraw consent at any time</strong> where we are relying on consent
                            to process your personal data. However, this will not affect the lawfulness of any
                            processing carried out before you withdraw your consent. If you withdraw your consent,
                            we may not be able to provide certain Services to you. We will advise you if this is the
                            case at the time you withdraw your consent.
                        </li>
                    </ul>
                    <p className="py-2">You will not have to pay a fee to access your personal data (or to exercise any of the other
                        rights). However, we may charge a reasonable fee if your request is clearly unfounded,
                        repetitive or excessive. Alternatively, we may refuse to comply with your request in these
                        circumstances.</p><p className="py-2">We may need to request specific information from you to help us confirm
                    your identity and ensure your right to access your personal data (or to exercise any of your
                    other rights). This is a security measure to ensure that personal data is not disclosed to
                    any person who has no right to receive it. We may also contact you to ask you for further
                    information in relation to your request to speed up our response.</p><p className="py-2">We try to respond to
                    all legitimate requests within one month. Occasionally it may take us longer than a month if
                    your request is particularly complex or you have made a number of requests. In this case, we
                    will notify you and keep you updated.</p>

                    <h2>11. Changes to this Privacy Policy</h2>
                    <p className="py-2">We
                        may change this Privacy Policy unilaterally and without prior notice, but any changes will
                        be posted on the Website. If we make changes that materially alter your privacy rights, we
                        will provide additional notice, such as via email or through the Services. If you disagree
                        with the changes to this Privacy Policy, you should stop using our Services, the Website or
                        any other aspect of our business. Your continued use of the Website or our Services
                        constitutes your agreement to all such changes.</p><p className="py-2">This version was last updated on
                    24.08.2022 and historic versions can be obtained by contacting us.</p>


                    <h2>12. Contact and
                        complains</h2>

                    <p className="py-2">For further information regarding the Privacy Policy and related practices
                        or in case of any suspected infringement of your privacy, please contact us at <a className="read-more-link"
                            href="mailto:contact@bitspreader.com">contact@bitspreader.com</a> or by post:
                        CodeFX&nbsp; Sp.z o.o. address: Zamknieta 10/1.5, 30-554, Krakow, Poland.</p><p className="py-2">If you
                    reside in the EEA, we will be the controller of your personal data provided to, or collected
                    by, or processed in connection with our Services. You have the right to make a complaint at
                    any time to the Polish Data Protection Inspectorate (address: Stawki 2,
                    00-193 Warszawa, Poland
                    e-mail address <a href="mailto:kancelaria@uodo.gov.pl" className="read-more-link">kancelaria@uodo.gov.pl</a>, phone +48 22
                    531-03-00). We would, however, appreciate the chance to deal with your concerns before you
                    approach the Data Protection Inspectorate, so please contact us in the first instance.</p>


                </div>


                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
